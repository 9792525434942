import { capitalize } from '../../../../../utils/text/capitalize';

export const getNewActions = arrayPermissions => {
  const actionsResult = [];
  const mappedActions = arrayPermissions.CREATE;
  mappedActions.forEach(name => {
    const nameTransform = capitalize(name).replace('_', ' ');
    const objectNav = {
      nameRoute: `new-${name.toLowerCase()}`,
      title: nameTransform,
    };
    actionsResult.push(objectNav);
  });
  return actionsResult;
};
