export const ICONS = Object.freeze({
  ADMIN: () => import('../../components/icons/AdminSvg'),
  PLATFORMS: () => import('../../components/icons/PlatformSvg'),
  CONNECTION: () => import('../../components/icons/PlatformSvg'),
  EXPENSE: () => import('../../components/icons/DemandSvg'),
  AD_OPS: () => import('../../components/icons/UserSvg'),
  ANALYTICS: () => import('../../components/icons/AnalyticsSvg'),
  ADVERTISER: () => import('../../components/icons/AdvertiserSvg'),
  PRODUCT: () => import('../../components/icons/ProductSvg'),
  CAMPAIGN: () => import('../../components/icons/CampaignSvg'),
  CAMPAIGN_GROUPS: () => import('../../components/icons/CampaignGroupSvg'),
  CATEGORY: () => import('../../components/icons/CategorySvg'),
  CLIENT: () => import('../../components/icons/ClientSvg'),
  CONTEXT: () => import('../../components/icons/AdminSvg'),
  CURRENCY: () => import('../../components/icons/CurrencySvg'),
  DASHBOARD: () => import('../../components/icons/DashboardSvg'),
  HOME: () => import('../../components/icons/DashboardSvg'),
  DEMAND: () => import('../../components/icons/DemandSvg'),
  FINANCE: () => import('../../components/icons/UserSvg'),
  FORMAT: () => import('../../components/icons/FormatSvg'),
  INVOICE: () => import('../../components/icons/InvoicingSvg'),
  LINE_INSERTION: () => import('../../components/icons/LineInsertionSvg'),
  PUBLISHER: () => import('../../components/icons/PublisherSvg'),
  POSITION: () => import('../../components/icons/PositionSvg'),
  REPORTING: () => import('../../components/icons/ReportingSvg'),
  SITE: () => import('../../components/icons/SiteSvg'),
  SUPPLY: () => import('../../components/icons/SupplySvg'),
  USER: () => import('../../components/icons/UserSvg'),
  OFFERS: () => import('../../components/icons/OffersSvg'),
});
